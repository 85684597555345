<template lang="pug">
  .filter
    input(type="text" v-model="searchInput" @input="onSearch" placeholder="Поиск по каталогу...")._input
    button(type="button" @click="onSearch")._btn
      icon(name="search" component="fixed-panel")._btn-ico
</template>
<script>
export default {
  name: "SearchFilter",
  data() {
    return {
      searchInput: "",
    }
  },
  methods: {
    onSearch() {
      if (this.searchInput.length > 2) {
        this.$emit("onSearch", this.searchInput);
      }
    }
  }
}
</script>
<style lang="scss">
.filter {
  @import '@/scss/vars.scss';
  width: 100%;
  max-width: 500px;
  display: flex;
  align-items: center;
  position: relative;
  margin-left: 40px;

  @media(max-width: 1199px) {
    max-width: 320px;
    margin-left: 20px;
  }

  @media(max-width: 991px) {
    max-width: 100%;
    margin: 15px 0 0 0;
  }

  &__input {
    font-size: 16px;
    color: $dark;
    border: 2px solid $dark;
    background: #F8F8F8;
    box-shadow: none;
    border-radius: 10px;
    padding: 12px 28px;
    width: 100%;
    height: auto;
    outline: none;
    -webkit-appearance: none;
    appearance: none;
    transition: all .18s linear;

    @media(max-width: 1199px) {
      font-size: 15px;
    }

    @media(max-width: 991px) {
      padding: 12px 20px;
    }

    @media(max-width: 767px) {
      border-width: 1px;
    }

    @media(max-width: 575px) {
      font-size: 14px;
    }
  }

  &__btn {
    display: none;

    @media(max-width: 767px) {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      width: 32px;
      height: 32px;
      right: 7px;
      top: calc(50% - 16px);
      background: $dark;
      border-radius: 5px;
    }
  }

  &__btn-ico {
    display: flex;
    width: 14px;
    height: 14px;
    fill: #FFF;
  }
}
</style>